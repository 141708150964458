import Image from 'assets/backgrounds/approach.jpg';

export const navigationItems = {
  services: {
    id: 'services',
  },
  team: {
    id: 'team',
  },
  fcic: {
    id: 'fcic',
  },
};

export const title = 'Über uns — FondsConsult';
export const description =
  'Spezialisierter Outsourcing-Partner für Wertpapierdienstleistungen: Kundenorientierte Lösungen mit Fokus auf Transparenz und Einhaltung der EU-Regulierungen.';
export const imageUrl = `${window.location.origin}${Image}`;
export const content = 'business.business';
