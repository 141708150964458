import { SEO } from 'components/common/SEO';
import { FC, useEffect, useState } from 'react';
import { content, description, imageUrl, title } from './config';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledSubtitle,
  StyledLink,
  StyledInnerLink,
  StyledInnerContainer,
  StyledFixedHeaderContainer,
} from './ImpressumPage.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { AppPaths } from 'urls/frontend';
import { useWindowSize } from 'hooks/useWindowSize';

export const ImpressumPage: FC = () => {
  const { isMobile } = useWindowSize();
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (isMobile) {
      if (offset < prevScrollPos && offset > 70) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    } else {
      const section = document.querySelector('.about-us');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        if (offset >= sectionTop) {
          setShowFixedHeader(true);
        } else {
          setShowFixedHeader(false);
        }
      }
    }
    setPrevScrollPos(offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, prevScrollPos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header />
        </StyledFixedHeaderContainer>
      )}
      <StyledContainer>
        <Header />
      </StyledContainer>
      <StyledContainer>
        <StyledInnerContainer display="flex" flexDirection="column" gap={4}>
          <Box>
            <StyledHeader variant="h1">Impressum</StyledHeader>
            <StyledContent variant="body" color={COLORS.typography.description}>
              FondsConsult Research GmbH
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Seidlstr. 26
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              80355 München
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Deutschland
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Vertreten durch:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Geschäftsführer: Rüdiger Sälzle, Michael Schmidt, Christian
              Michel, Said Yakhloufi
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Kontakt:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Telefon:{' '}
              <StyledLink href="tel:+4989244180230">
                +49 (89) 24 41 802 30
              </StyledLink>
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              E-Mail:{' '}
              <StyledLink href="mailto:research@fondsconsult.de">
                research@fondsconsult.de
              </StyledLink>
            </StyledContent>
          </Box>

          <Box>
            <StyledContent variant="body" color={COLORS.typography.description}>
              <StyledInnerLink to={AppPaths.main}>
                www.fondsconsult.de
              </StyledInnerLink>
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Registereintrag:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Eingetragen im Handelsregister.
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Eingetragen im Handelsregister.
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Registernummer: HRB 132144
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Umsatzsteuer:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              DE 211603994
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Said Yakhloufi
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              FondsConsult Research GmbH
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Seidlstr. 26
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              80335 München
            </StyledContent>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Angaben gemäß Finanzanalyseverordnung (FinAnV) und zuständige
              Aufsichtsbehörde:
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Die Marktmissbrauchsverordnung und die Delegierte Verordnung
              2016/958 schreiben vor, dass bei der Erstellung von
              Anlagestrategie- und Anlageempfehlungen (früher „Finanzanalysen“)
              Mindestanforderungen einzuhalten sind. FondsConsult Research GmbH
              erstellt Analysen für Investmentfonds entsprechend den Vorgaben.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                In Deutschland ist FondsConsult Research GmbH bei der
                Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin),
                Marie-Curie-Str. 24-28 in 60439 Frankfurt am Main, registriert.
                FondsConsult Research GmbH hat weder eine Registrierung in
                Großbritannien noch bei einer US-Behörde.
              </StyledContent>
            </Box>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Streitschlichtung
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{' '}
              <StyledLink
                href="https://ec.europa.eu/consumers/odr"
                target="_blank"
              >
                https://ec.europa.eu/consumers/odr
              </StyledLink>
              . Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </StyledContent>
            </Box>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Haftung für Inhalte
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
                Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.
              </StyledContent>
            </Box>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Haftung für Links
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                derartige Links umgehend entfernen.
              </StyledContent>
            </Box>
          </Box>

          <Box>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Urheberrecht
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet.
            </StyledContent>
            <Box pt={2}>
              <StyledContent
                variant="body"
                color={COLORS.typography.description}
              >
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
                von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.
              </StyledContent>
            </Box>
          </Box>
        </StyledInnerContainer>
      </StyledContainer>
      <Footer />
    </>
  );
};
