import { Button } from 'components/common/Button';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { FC } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { COLORS } from 'theme/colors';
import {
  StyledContainer,
  StyledHeader,
  StyledInnerContainer,
  StyledItem,
  StyledItemContainer,
  StyledItemsList,
  StyledLabel,
  StyledTopContainer,
} from './Services.styled';
import { servicesItems } from './config';
import { navigationItems } from 'views/AboutUs/config';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactAboutUsAction,
  ContactCategory,
} from 'utils/google-analytics/events/contacts';

export const Services: FC = () => {
  const { services } = navigationItems;
  const navigate = useNavigate();

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactAboutUsAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer
      id={services.id}
      pt={8.75}
      px={8}
      className="services-section"
    >
      <StyledTopContainer display="flex" justifyContent="space-between">
        <StyledHeader variant="h2">
          Als spezialisierter Outsourcing-Partner bieten wir umfassende
          Dienstleistungen im Wertpapiergeschäft
        </StyledHeader>
        <Button
          variant={ButtonVariant.PRIMARY}
          rightIcon={
            <ArrowIcon
              stroke={COLORS.info.selectedBorder}
              style={{ transform: 'rotate(-90deg)' }}
            />
          }
          onClick={onContactClick}
        >
          Kontaktieren Sie uns
        </Button>
      </StyledTopContainer>
      <StyledInnerContainer>
        {servicesItems.map((item) => (
          <StyledItemContainer isLight={item.isLight}>
            {item.icon}
            <StyledLabel
              variant="h3"
              color={
                item.isLight
                  ? COLORS.typography.primary
                  : COLORS.background.secondary
              }
            >
              {item.label}
            </StyledLabel>
            <StyledItemsList>
              {item.items.map((point) => (
                <StyledItem isLight={item.isLight}>{point}</StyledItem>
              ))}
            </StyledItemsList>
          </StyledItemContainer>
        ))}
      </StyledInnerContainer>
    </StyledContainer>
  );
};
