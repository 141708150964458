import styled from 'styled-components';
import { Box } from '../../common/Box';
import BackgroundImage from 'assets/backgrounds/approach.jpg';
import MobileBackgroundImage from 'assets/backgrounds/approach-mobile.jpg';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  ${SIZES.media.desktop} {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);
    min-height: calc(100vh - 52px);
  }
`;

export const StyledLeftContainer = styled(Box)`
  padding: ${SIZES.spacing(4)} ${SIZES.spacing(2.5)} ${SIZES.spacing(8)}
    ${SIZES.spacing(2.5)};

  ${SIZES.media.desktop} {
    padding: 0 ${SIZES.spacing(14.5)} 0 ${SIZES.spacing(8)};
  }
`;

export const StyledRightContainer = styled(Box)`
  background-image: url(${MobileBackgroundImage});
  background-size: cover;
  width: 100%;
  height: 363px;

  ${SIZES.media.phone} {
    height: 65vh;
    background-position-y: center;
    background-position-x: center;
  }

  ${SIZES.media.tablet} {
    background-image: url(${BackgroundImage});
  }

  ${SIZES.media.desktop} {
    height: 100%;
  }
`;
