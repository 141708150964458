import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)} ${SIZES.spacing(8)};
  scroll-margin: 60px;

  ${SIZES.media.desktop} {
    padding: ${SIZES.spacing(12.5)} ${SIZES.spacing(8)};
    scroll-margin: 0;
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 48px;
  margin-bottom: 24px;
`;

export const StyledTextContainer = styled(Box)`
  margin: 0 auto;
  padding-bottom: ${SIZES.spacing(5)};

  ${SIZES.media.tablet} {
    width: 67%;
    padding-bottom: ${SIZES.spacing(8.75)};
  }
`;

export const StyledTeamMembersContainer = styled(Box)`
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: 15px 10px;

  ${SIZES.media.tablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 40px 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  font-size: 16px;
  line-height: 29px;
`;
