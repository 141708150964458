import { FC } from 'react';
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledLeftContainer,
  StyledRightContainer,
} from './FCICSection.styled';
import { navigationItems } from 'views/AboutUs/config';

export const FCICSection: FC = () => {
  const { fcic } = navigationItems;

  return (
    <StyledContainer id={fcic.id} className="about-us">
      <StyledLeftContainer display="flex" flexDirection="column" gap={3}>
        <StyledHeader variant="h2">
          FondsConsult Investment Committee (FCIC){' '}
        </StyledHeader>
        <StyledContent variant="body">
          Unser Investment Committee, bestehend aus erfahrenen Analysten und
          einem Vorsitzenden, erarbeitet fundierte Handlungsempfehlungen für ein
          breites Spektrum von Investmentfonds. In regelmäßigen Sitzungen werden
          Empfehlungen für Publikumsfonds, ETFs und illiquide Fonds wie ELTIFs
          intensiv diskutiert und per Abstimmung festgelegt. Dabei kombinieren
          wir die präzise FCR-Quant-Methodik mit qualitativen
          Experteneinschätzungen. Unser Ziel: unabhängige, sorgfältig geprüfte
          Empfehlungen, die unseren Kunden eine solide Grundlage für ihre
          Anlageentscheidungen in der dynamischen Finanzwelt bieten.
        </StyledContent>
      </StyledLeftContainer>
      <StyledRightContainer />
    </StyledContainer>
  );
};
