import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

type StyledNavBarItemProps = {
  active: boolean;
};

export const StyledContainer = styled(Box)`
  padding-left: ${SIZES.spacing(2.5)};
  padding-right: ${SIZES.spacing(2.5)};

  ${SIZES.media.desktop} {
    padding-left: ${SIZES.spacing(8)};
    padding-right: ${SIZES.spacing(8)};
  }
`;

export const StyledTopSectionContainer = styled(Box)`
  padding-top: ${SIZES.spacing(3)};
  scroll-margin: 28px;

  ${SIZES.media.phone} {
    padding-top: ${SIZES.spacing(4)};
    scroll-margin: 0;
  }

  ${SIZES.media.desktop} {
    padding-top: ${SIZES.spacing(10.5)};
  }
`;

export const StyledSectionContainer = styled(Box)`
  padding-top: ${SIZES.spacing(5)};
  scroll-margin: 50px;

  ${SIZES.media.phone} {
    padding-top: ${SIZES.spacing(12.5)};
    scroll-margin: 0;
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 32px;
  line-height: 38px;

  ${SIZES.media.phone} {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const StyledContent = styled(Typography)`
  font-size: 14px;
  line-height: 25px;

  & span {
    font-weight: 600;
    color: ${COLORS.typography.main};
  }

  ${SIZES.media.phone} {
    font-size: 16px;
    line-height: 29px;
  }
`;

export const StyledNavBarContainer = styled.nav`
  border-left: 1px solid ${COLORS.stroke.main};
  padding-left: 24px;
  margin-top: 50px;
  margin-bottom: 40px;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  width: 260px;
  height: 100%;
  box-sizing: border-box;

  ${SIZES.media.desktop} {
    width: 340px;
    margin-top: 100px;
  }
`;

export const StyledNavBarHeader = styled(Typography)`
  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 400;
`;

export const StyledNavBarItemsContainer = styled.ul`
  padding-inline-start: 0;
  list-style-type: none;
  margin-top: 32px;
  margin-bottom: 112px;
`;

export const StyledNavBarItem = styled.li<StyledNavBarItemProps>`
  padding-top: 20px;

  & a {
    font-family: 'Inter', sans-serif;
    line-height: 20px;
    font-size: 14px;
    text-decoration: none;
    text-underline-offset: 3px;
    color: ${({ active }) =>
      active ? COLORS.typography.main : COLORS.typography.description};

    &:hover {
      text-decoration: underline;
    }
  }

  &::before {
    ${({ active }) => (active ? "content: '';" : '')}
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${COLORS.info.selectedBorder};
    border-radius: 50%;
    margin-right: 16px;
  }
`;
