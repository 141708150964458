import React, { FC, useRef } from 'react';
import { MobileNavigationMenuProps } from './MobileNavigationMenu.d';
import {
  MenuLinkItem,
  MenuSectionName,
  StyledContainer,
  StyledDot,
  StyledInnerContainer,
  StyledLink,
  StyledLinks,
  StyledLinksContainer,
  StyledLogoLink,
  StyledOverlay,
} from './MobileNavigationMenu.styled';
import { useClickOutside } from 'hooks/useClickOutside';
import { Box } from 'components/common/Box';
import { AppPaths } from 'urls/frontend';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/light-logo.svg';
import { CloseButton } from 'components/common/Buttons/CloseButton';
import { navigationItems } from '../config';
import { COLORS } from 'theme/colors';
import { Button } from 'components/common/Button';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  LoginCategory,
  LoginClickedAction,
} from 'utils/google-analytics/events/login';
import { APP_URL } from 'urls/api';
import {
  ContactAboutUsAction,
  ContactCategory,
} from 'utils/google-analytics/events/contacts';

export const MobileNavigationMenu: FC<MobileNavigationMenuProps> = ({
  open,
  setOpen,
}) => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isHomePage = pathname === AppPaths.main;

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const onLoginClick = () => {
    TrackGoogleAnalyticsEvent(
      LoginCategory,
      LoginClickedAction,
      window.location.pathname,
    );
    window.location.href = APP_URL;
  };

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactAboutUsAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <>
      {open && (
        <StyledContainer>
          <StyledInnerContainer ref={wrapperRef} open={open}>
            <Box
              px={2.5}
              py={1.8}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <StyledLogoLink
                to={AppPaths.main}
                isClickable={!isHomePage}
                onClick={() => setOpen(false)}
              >
                <Logo />
              </StyledLogoLink>
              <CloseButton onClick={() => setOpen(false)} />
            </Box>
            <StyledLinksContainer
              px={2.5}
              pt={2.5}
              pb={3.5}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <StyledLinks>
                <React.Fragment key="home-page">
                  <MenuLinkItem key="home" isClickable={!isHomePage}>
                    {isHomePage && <StyledDot />}
                    <StyledLink
                      to={AppPaths.main}
                      isClickable={!isHomePage}
                      onClick={() => setOpen(false)}
                    >
                      Home
                    </StyledLink>
                  </MenuLinkItem>
                </React.Fragment>
                {Object.values(navigationItems).map((category, index) => (
                  <React.Fragment key={`${category}-${index}`}>
                    {category.categoryName && (
                      <MenuSectionName
                        variant="overline"
                        weight="medium"
                        color={COLORS.background.secondary}
                      >
                        {category.categoryName}
                      </MenuSectionName>
                    )}
                    {category.items.map((item) => {
                      const isCurrentPage = `${pathname}${hash}` === item.link;
                      return (
                        <MenuLinkItem
                          key={item.link}
                          isClickable={!isCurrentPage}
                        >
                          {isCurrentPage && <StyledDot />}
                          <StyledLink
                            smooth
                            to={item.link}
                            isClickable={!isCurrentPage}
                            onClick={() => setOpen(false)}
                          >
                            {item.label}
                          </StyledLink>
                        </MenuLinkItem>
                      );
                    })}
                  </React.Fragment>
                ))}
              </StyledLinks>
              <Box display="flex" flexDirection="column" gap={2}>
                <Button
                  fullWidth
                  variant={ButtonVariant.OUTLINED}
                  onClick={onLoginClick}
                >
                  Anmelden
                </Button>
                <Button
                  fullWidth
                  variant={ButtonVariant.CONTAINED}
                  onClick={onContactClick}
                >
                  Kontaktieren Sie uns
                </Button>
              </Box>
            </StyledLinksContainer>
          </StyledInnerContainer>
          {open && <StyledOverlay />}
        </StyledContainer>
      )}
    </>
  );
};
