import { FC, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { routes } from './config/routes';
import { StyledToastContainer } from 'components/common/Toast/Toast.styled';
import InitializeGoogleAnalytics from 'utils/google-analytics';
import { NotFoundPage } from 'views/NotFoundPage/NotFoundPage';

function App() {
  const env = process.env.REACT_APP_NODE_ENV;
  const isProduction = env === 'production';
  const getRoute = (path: string, Component: FC) => {
    return <Route key={path} path={path} element={<Component />} />;
  };

  useEffect(() => {
    if (
      isProduction &&
      (window as any).CookieConsent &&
      (window as any).CookieConsent.consent.statistics &&
      (window as any).CookieConsent.consent.marketing
    ) {
      InitializeGoogleAnalytics();
    }
  }, []);

  return (
    <>
      <StyledToastContainer />
      <Router>
        <Routes>
          {routes.map(({ path, component }) => getRoute(path, component))}
          <Route path="*" key="not-found-page" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
