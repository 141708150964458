import { FC } from 'react';
import { HeaderSectionProps } from './HeaderSection.d';
import {
  StyledContainer,
  StyledHeader,
  StyledSubHeader,
} from './HeaderSection.styled';
import { Header } from '../Header/Header';
import { Box } from '../Box';

export const HeaderSection: FC<HeaderSectionProps> = ({
  label,
  description,
  secondDescription,
}) => {
  return (
    <StyledContainer>
      <Header variant="secondary" />
      <Box
        pt={7}
        px={2.5}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <StyledHeader variant="h1" textAlign="center">
          {label}
        </StyledHeader>
        <StyledSubHeader variant="body" textAlign="center">
          {description}
        </StyledSubHeader>
        {secondDescription && (
          <StyledSubHeader variant="body" textAlign="center">
            {secondDescription}
          </StyledSubHeader>
        )}
      </Box>
    </StyledContainer>
  );
};
