import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';
import { hexToRGB } from 'utils/common';

export const StyledAboutUsContainer = styled(Box)`
  background-color: ${hexToRGB(COLORS.background.secondary, '0.8')};
  height: fit-content;
  padding-right: ${SIZES.spacing(5)};

  & p {
    line-height: 25px;
  }

  ${SIZES.media.phone} {
    padding-right: ${SIZES.spacing(8)};
  }
`;
