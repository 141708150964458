import { HeaderSection } from 'components/common/HeaderSection';
import { FC, useEffect, useState } from 'react';
import { StyledFixedHeaderContainer } from './AboutUs.styled';
import { Header } from 'components/common/Header';
import { ContactSection } from 'components/common/ContactSection';
import { Footer } from 'components/common/Footer';
import { FCICSection } from 'components/AboutUs/FCICSection';
import { Services } from 'components/AboutUs/Services/Services';
import { Team } from 'components/AboutUs/Team';
import { SEO } from 'components/common/SEO';
import { content, description, imageUrl, title } from './config';
import { useWindowSize } from 'hooks/useWindowSize';

export const AboutUs: FC = () => {
  const { isMobile } = useWindowSize();
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (isMobile) {
      if (offset < prevScrollPos && offset > 70) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    } else {
      const section = document.querySelector('.services-section');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        if (offset >= sectionTop) {
          setShowFixedHeader(true);
        } else {
          setShowFixedHeader(false);
        }
      }
    }
    setPrevScrollPos(offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, prevScrollPos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <HeaderSection
        label="Über uns"
        description="Mehrwert durch unabhängige Fondsanalyse"
        secondDescription="Innovativ & Bewährt"
      />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header expandableMenuVariant="secondary" />
        </StyledFixedHeaderContainer>
      )}
      <Services />
      <Team />
      <FCICSection />
      <ContactSection />
      <Footer />
    </>
  );
};
