import { TeamMemberProps } from './TeamMember/TeamMember.d';
import Member1Photo from 'assets/team/Rudiger-Salzle.jpg';
import Member2Photo from 'assets/team/Michael-Schmidt.jpg';
import Member3Photo from 'assets/team/Brigitte-Perschke.png';
import Member4Photo from 'assets/team/Jan-Richter.png';
import Member5Photo from 'assets/team/Said-Yakhloufi.png';
import Member6Photo from 'assets/team/Christian-Michel.png';
import Member7Photo from 'assets/team/Katharina-Ehrhardt.png';
import Member8Photo from 'assets/team/Michael-Wimmer.png';
import Member9Photo from 'assets/team/Maximilian-Wellner.png';
import Member10Photo from 'assets/team/Dominik-Wagner.png';

export const teamMembers: TeamMemberProps[] = [
  {
    id: '1',
    name: 'Rüdiger Sälzle',
    position: 'Geschäftsführer',
    photo: Member1Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '2',
    name: 'Christian Michel',
    position: 'Geschäftsführer',
    photo: Member6Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '3',
    name: 'Said Yakhloufi',
    position: 'Geschäftsführer/Senior Analyst',
    photo: Member5Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '4',
    name: 'Michael Schmidt',
    position: 'Geschäftsführer',
    photo: Member2Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '5',
    name: 'Brigitte Perschke',
    position: 'Daten- und Qualitätsmanagement',
    photo: Member3Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '6',
    name: 'Jan Richter',
    position: 'Senior Analyst',
    photo: Member4Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '7',
    name: 'Katharina Ehrhardt',
    position: 'Senior Analyst',
    photo: Member7Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '8',
    name: 'Michael Wimmer',
    position: 'Senior Analyst',
    photo: Member8Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '9',
    name: 'Maximilian Wellner',
    position: 'Analyst',
    photo: Member9Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
  {
    id: '10',
    name: 'Dominik Wagner',
    position: 'Junior Analyst',
    photo: Member10Photo,
    // linkedingLink: 'https://www.linkedin.com/',
  },
];
