import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled, { keyframes } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-70px);
  }
`;

type StyledFixedHeaderContainerProps = {
  showFixedHeader: boolean;
};

export const StyledFixedHeaderContainer = styled(
  Box,
)<StyledFixedHeaderContainerProps>`
  position: fixed;
  top: 0;
  z-index: 1;

  background-color: ${COLORS.background.secondary};
  width: 100%;

  animation: ${({ showFixedHeader }) => (showFixedHeader ? fadeIn : fadeOut)}
    0.5s ease-in-out;
`;

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
`;

export const StyledHeader = styled(Typography)`
  font-size: 48px;
  line-height: 50px;

  ${SIZES.media.phone} {
    font-size: 64px;
    line-height: 65px;
  }
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 18px;
  line-height: 26px;
  color: ${COLORS.typography.description};
  text-align: center;

  margin-top: 24px;

  ${SIZES.media.phone} {
    width: 45%;
  }
`;
