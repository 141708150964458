import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { SEOProps } from './SEO.d';

export const SEO: FC<SEOProps> = ({
  title,
  description,
  imageUrl,
  content,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content={content} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
};
