import { Chip } from '@mui/material';
import { styled } from '@mui/system';
import { COLORS } from 'theme/colors';
import { StyledChipProps } from './Chip.d';

export const StyledChip = styled(Chip)<StyledChipProps>(
  ({ customColor, customBackground, customBorderBolor, customSize }) => ({
    color: customColor || COLORS.background.secondary,
    background: customBackground || 'rgba(255, 255, 255, 0.2)',
    height: customSize === 'small' ? '12px' : '23px',
    fontSize: customSize === 'small' ? '10px' : '12px',
    fontWeight: customSize === 'small' ? 400 : 500,
    fontFamily: '"Inter", sans-serif',
    padding: customSize === 'small' ? '11px 5px' : '11.5px 6px',
    overflow: 'hidden',
    borderRadius: 4,
    transitionDuration: `0.2s`,

    ...(customBorderBolor ? { border: `1px solid ${customBorderBolor}` } : {}),

    '.MuiChip-label': {
      padding: '2px 2.3px !important',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 500,
      '@media screen and (min-width: 450px)': {
        padding: '2px 6px !important',
      },
    },
    '@media screen and (min-width: 450px)': {
      fontSize: customSize === 'small' ? '10px' : '14px',
      padding: customSize === 'small' ? '11px 5px' : '13.5px 6px',
      height: customSize === 'small' ? '12px' : '36px',
    },
  }),
);
