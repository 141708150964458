import { Button } from 'components/common/Button';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { Typography } from 'components/common/Typography';
import { FC } from 'react';
import { COLORS } from 'theme/colors';
import { StyledAboutUsContainer } from './AboutUs.styled';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import { AboutUsCategory } from 'utils/google-analytics/events/aboutUs';
import { ContactAboutUsAction } from 'utils/google-analytics/events/contacts';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';

export const AboutUs: FC = () => {
  const navigate = useNavigate();
  const onAboutUsClick = () => {
    TrackGoogleAnalyticsEvent(
      AboutUsCategory,
      ContactAboutUsAction,
      window.location.pathname,
    );
    navigate(AppPaths.aboutUs);
  };

  return (
    <StyledAboutUsContainer
      py={5}
      pl={5}
      display="flex"
      flexDirection="column"
      gap={5}
    >
      <Typography variant="body">
        Seit über drei Jahrzehnten bietet FondsConsult als eigenständiges
        Beratungsinstitut fundierte Kompetenz in der Investmentwelt. Unsere
        Expertise umfasst die sorgfältige Analyse und Auswahl von
        Investmentfonds aus allen relevanten Anlageklassen. Im Zentrum unserer
        Tätigkeit steht die umfassende Beratung für ein breites Spektrum an
        Finanzakteuren: von Banken in ihrem Depot A- und B-Geschäft über
        Versicherungen und Fonds-Distributoren bis hin zu wichtigen
        Entscheidungsträgern im Kapitalanlage-Bereich. Erfahren Sie mehr über
        uns.
      </Typography>
      <Button
        variant={ButtonVariant.SECONDARY}
        rightIcon={<ArrowIcon stroke={COLORS.info.selectedBorder} />}
        onClick={onAboutUsClick}
      >
        Über uns
      </Button>
    </StyledAboutUsContainer>
  );
};
