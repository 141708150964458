import { ReactComponent as AnalysisIcon } from 'assets/icons/analysis.svg';
import { ReactComponent as MarketExpertiseIcon } from 'assets/icons/market-expertise.svg';
import { ReactComponent as TechnologyIcon } from 'assets/icons/technology.svg';
import { ReactComponent as RegulatorySupportIcon } from 'assets/icons/regulatory-support.svg';

export const servicesItems = [
  {
    icon: <AnalysisIcon />,
    label: 'Analyse und Beratung',
    items: [
      'Erstellung compliance-gerechter Handlungsempfehlungen und Produktprüfungen',
      'Kontinuierliches Monitoring von Selektions- und Bestandslisten',
      'Ad-hoc Unterstützung durch unseren Analyst Desk',
    ],
    isLight: true,
  },
  {
    icon: <MarketExpertiseIcon />,
    label: 'Marktexpertise und Innovation',
    items: [
      'Best Practice Sharing für Private Banking und Wealth Management',
      'Einführung neuer Anlageklassen, insbesondere im Bereich Private Market Fonds',
      'Durchführung aktueller Studien zu Branchentrends',
    ],
    isLight: false,
  },

  {
    icon: <TechnologyIcon />,
    label: 'Technologie und Selbstservice',
    items: [
      'Zugang zur intuitiven FondsConsult Datenbank für eigenständige Analysen',
    ],
    isLight: true,
  },
  {
    icon: <RegulatorySupportIcon />,
    label: 'Regulatorische Unterstützung',
    items: [
      'Hilfe bei der Umsetzung regulatorischer Anforderungen, z.B. der Kleinanlegerstrategie',
      'Unser Ansatz vereint Transparenz, Unabhängigkeit und Kundenorientierung - stets im Einklang mit EU-Regulierungen und Marktanforderungen',
    ],
    isLight: true,
  },
];
