export const tags = [
  'Aktien',
  'Renten',
  'Mischfonds',
  'ETFs',
  'Liquid Alternatives',
  'ELTIFs',
  'Offene Immobilienfonds',
  'Offene Infrastruktur-Sondervermögen',
  'Stiftungsfonds',
];
