import { FC, useEffect, useState } from 'react';
import { Footer } from '../../components/common/Footer';
import { Helmet } from 'react-helmet';
import { Button } from 'components/common/Button';
import { useNavigate } from 'react-router-dom';
import { AppPaths } from 'urls/frontend';
import { Header } from 'components/common/Header';
import {
  StyledContainer,
  StyledFixedHeaderContainer,
  StyledHeader,
  StyledSubHeader,
} from './NotFoundPage.styled';
import { Box } from 'components/common/Box';
import { useWindowSize } from 'hooks/useWindowSize';

export const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (isMobile) {
      if (offset < prevScrollPos && offset > 70) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    } else {
      const section = document.querySelector('.about-us');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        if (offset >= sectionTop) {
          setShowFixedHeader(true);
        } else {
          setShowFixedHeader(false);
        }
      }
    }
    setPrevScrollPos(offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, prevScrollPos]);

  const handleButton = () => {
    navigate(AppPaths.main);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Seite nicht gefunden — FondsConsult</title>
        <meta
          name="description"
          content="Seite nicht gefunden — FondsConsult"
        />
      </Helmet>
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header />
        </StyledFixedHeaderContainer>
      )}
      <StyledContainer>
        <Header />
      </StyledContainer>
      <StyledContainer>
        <Box
          pt={9.4}
          px={2}
          pb={10}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
        >
          <StyledHeader variant="h1" textAlign="center">
            404 Fehler: Seite nicht gefunden
          </StyledHeader>
          <StyledSubHeader variant="body" textAlign="center">
            Leider können wir die von Ihnen gesuchte Seite nicht finden.
          </StyledSubHeader>
          <Button onClick={handleButton}>Zur Startseite gehen</Button>
        </Box>
      </StyledContainer>
      <Footer />
    </>
  );
};
