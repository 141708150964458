import { FC, useEffect, useState } from 'react';
import { MainScreen } from 'components/MainPage/MainScreen';
import { OurApproach } from 'components/MainPage/OurApproach';
import { Studies } from 'components/MainPage/Studies';
import { Recommendations } from '../../components/MainPage/Recommendations';
import { Footer } from '../../components/common/Footer';
import { Header } from '../../components/common/Header';
import { StyledFixedHeaderContainer } from './MainPage.styled';
import { content, description, imageUrl, title } from './config';
import { SEO } from 'components/common/SEO';
import { useWindowSize } from 'hooks/useWindowSize';

export const MainPage: FC = () => {
  const { isMobile } = useWindowSize();
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (isMobile) {
      if (offset < prevScrollPos && offset > 70) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    } else {
      const section = document.querySelector('.about-us');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        if (offset >= sectionTop) {
          setShowFixedHeader(true);
        } else {
          setShowFixedHeader(false);
        }
      }
    }
    setPrevScrollPos(offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, prevScrollPos]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <MainScreen />
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header />
        </StyledFixedHeaderContainer>
      )}
      <OurApproach />
      <Studies />
      <Recommendations />
      <Footer />
    </>
  );
};
