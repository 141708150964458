import { styled as MUIStuled } from '@mui/material/styles';
import styled from 'styled-components';
import { HashLink as Link } from 'react-router-hash-link';
import { StyledItemProps } from './ExpandedNavMenu.d';
import { COLORS } from '../../../../theme/colors';
import Background from 'assets/backgrounds/menu-background.jpg';
import { MenuItem, Popper } from '@mui/material';

export const StyledMenu = MUIStuled(Popper)<StyledItemProps>(({ isLight }) => ({
  borderRadius: 8,
  marginTop: '12px !important',
  marginLeft: '6px !important',
  ...(isLight
    ? { backgroundColor: COLORS.background.secondary }
    : {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }),
  minWidth: 254,
  maxWidth: 260,
  color: 'rgb(55, 65, 81)',
  boxShadow:
    'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  paddingBottom: '24px',
  paddingTop: '32px',
}));

export const StyledMenuItem = MUIStuled(MenuItem)<StyledItemProps>(
  ({ isLight }) => ({
    padding: '8px 32px',
    letterSpacing: 0,
    whiteSpace: 'normal',
    '&:hover': {
      background: 'none',
      '& a': {
        color: isLight ? COLORS.accent.primary : COLORS.background.secondary,
      },
    },
  }),
);

export const StyledLink = styled(Link)<StyledItemProps>`
  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  text-decoration: none;
  color: ${({ isLight }) =>
    isLight ? COLORS.typography.main : COLORS.typography.info};
`;

export const StyledCategory = styled.li<StyledItemProps>`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  list-style-type: none;
  line-height: 12px;
  color: ${({ isLight }) =>
    isLight ? COLORS.info.selectedBorder : COLORS.typography.info};
  padding: 0 32px 8px;
`;
