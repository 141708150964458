import { FC } from 'react';
import { TeamMemberProps } from './TeamMember.d';
import { Box } from 'components/common/Box';
import {
  StyledInnerContainer,
  StyledLinkedinLink,
  StyledName,
  StyledPhoto,
  StyledPosition,
} from './TeamMember.styled';
import { ReactComponent as LinkedinIcon } from 'assets/icons/linkedin.svg';
import { COLORS } from 'theme/colors';

export const TeamMember: FC<TeamMemberProps> = ({
  name,
  photo,
  position,
  linkedingLink,
}) => {
  return (
    <Box>
      <StyledPhoto src={photo} />
      <StyledInnerContainer
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <StyledName variant="h3">{name}</StyledName>
          <StyledPosition variant="body" color={COLORS.typography.description}>
            {position}
          </StyledPosition>
        </Box>
        {!!linkedingLink && (
          <StyledLinkedinLink href={linkedingLink}>
            <LinkedinIcon fill={COLORS.stroke.primary} />
          </StyledLinkedinLink>
        )}
      </StyledInnerContainer>
    </Box>
  );
};
