export const pxToRem = (px: number) => `${px / 16}rem`;

export const hexToRGB = (hex: string, alpha: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha})` : `rgb(${r}, ${g}, ${b})`;
};

export const isValidForm = <T extends object>(errors: T) =>
  Object.keys(errors).length < 0;

export const debounce = (callback: (...args: any[]) => void, ms = 1500) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return (...args: any[]) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      timer = undefined;
      callback(...args);
    }, ms);
  };
};
