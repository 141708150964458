import { FC } from 'react';
import { StyledContainer, StyledDescription, StyledTitle } from './ContactSection.styled';
import { COLORS } from 'theme/colors';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { Button } from 'components/common/Button';
import { Box } from 'components/common/Box';
import { useNavigate } from 'react-router';
import { AppPaths } from 'urls/frontend';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactSectionAction,
} from 'utils/google-analytics/events/contacts';

export const ContactSection: FC = () => {
  const navigate = useNavigate();

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactSectionAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={3}
    >
      <StyledTitle
        variant="h2"
        color={COLORS.background.secondary}
        textAlign="center"
      >
        Dürfen wir Ihnen weiterhelfen?
      </StyledTitle>
      <StyledDescription
        variant="body"
        color={COLORS.background.secondary}
        textAlign="center"
      >
        Wenden Sie sich bei Fragen gerne jederzeit an uns
      </StyledDescription>
      <Box display="flex" gap={2} pt={2}>
        <Button variant={ButtonVariant.CONTAINED} onClick={onContactClick}>
          Kontaktieren Sie uns
        </Button>
      </Box>
    </StyledContainer>
  );
};
