import { FC } from 'react';
import {
  StyledContainer,
  StyledContent,
  StyledHeader,
  StyledNavBarContainer,
  StyledNavBarHeader,
  StyledNavBarItem,
  StyledNavBarItemsContainer,
  StyledSectionContainer,
  StyledSubtitle,
  StyledTopSectionContainer,
} from './MainSection.styled';
import { Box } from 'components/common/Box';
import { COLORS } from 'theme/colors';
import { navigationItems } from './config';
import { HashLink as Link } from 'react-router-hash-link';
import { AppPaths } from 'urls/frontend';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { Button } from 'components/common/Button';
import { ButtonVariant } from 'components/common/Button/Button.enums';
import { TrackGoogleAnalyticsEvent } from 'utils/google-analytics';
import {
  ContactCategory,
  ContactMethodologyAction,
} from 'utils/google-analytics/events/contacts';
import { useWindowSize } from 'hooks/useWindowSize';

export const MainSection: FC = () => {
  const { isTablet } = useWindowSize();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [
    fundSelectionApproach,
    smartFundBenchmarking,
    esgCredibilityScore,
    privateMarkets,
  ] = navigationItems;

  const onContactClick = () => {
    TrackGoogleAnalyticsEvent(
      ContactCategory,
      ContactMethodologyAction,
      window.location.pathname,
    );
    navigate(AppPaths.contacts);
  };

  return (
    <StyledContainer className="main-section" display="flex">
      {!isTablet && (
        <StyledNavBarContainer>
          <StyledNavBarHeader variant="body">Methodik</StyledNavBarHeader>
          <StyledNavBarItemsContainer>
            {navigationItems.map((item) => (
              <StyledNavBarItem
                key={item.id}
                active={hash.slice(1) === item.id}
              >
                <Link smooth to={`${AppPaths.methodology}#${item.id}`}>
                  {item.label}
                </Link>
              </StyledNavBarItem>
            ))}
          </StyledNavBarItemsContainer>
          <Button
            variant={ButtonVariant.PRIMARY}
            rightIcon={
              <ArrowIcon
                stroke={COLORS.info.selectedBorder}
                style={{ transform: 'rotate(-90deg)' }}
              />
            }
            onClick={onContactClick}
          >
            Kontaktieren Sie uns
          </Button>
        </StyledNavBarContainer>
      )}
      <Box pt={2} pb={8.8} style={{ flex: '1' }}>
        <StyledTopSectionContainer
          id={fundSelectionApproach.id}
          className={fundSelectionApproach.id}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <StyledHeader variant="h2">Unser Fondsselektionsansatz</StyledHeader>
          <div>
            <StyledSubtitle variant="body" color={COLORS.typography.main}>
              Ganzheitliche Analyse für fundierte Empfehlungen
            </StyledSubtitle>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Bei FondsConsult setzen wir auf einen umfassenden Ansatz zur
              Fondsselektion, der quantitative Präzision mit qualitativer
              Expertise vereint. Das Herzstück unserer quantitativen Analyse
              bildet der FCR-Quant-Score, ein in Zusammenarbeit mit der
              Technischen Universität München entwickeltes Instrument, das
              sowohl Performance- als auch Risiko-Aspekte berücksichtigt. Diese
              datengetriebene Bewertung ergänzen wir durch eine eingehende
              qualitative Analyse, bei der persönliche Gespräche mit den
              Portfoliomanagern der Fondsgesellschaften im Mittelpunkt stehen.
              Mit jährlich bis zu 600 Fondsmanager-Interviews gewinnen wir
              wertvolle Einblicke in Strategien, Prozesse und
              Markteinschätzungen. Die Erkenntnisse aus beiden Analyseebenen
              fließen in unser monatlich tagendes FondsConsult Investment
              Committee ein, wo wir klare Handlungsempfehlungen für jeden
              analysierten Investmentfonds erarbeiten und beschließen. Dieser
              mehrstufige Prozess ermöglicht es uns, unseren Kunden präzise,
              unabhängige und fundierte Empfehlungen zu liefern, die als solide
              Entscheidungsgrundlage im dynamischen Fondsmarkt dienen.
            </StyledContent>
          </div>
        </StyledTopSectionContainer>
        <StyledSectionContainer
          id={smartFundBenchmarking.id}
          className={smartFundBenchmarking.id}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <StyledHeader variant="h2">Smart Fund Benchmarking</StyledHeader>
          <div>
            <StyledContent variant="body" color={COLORS.typography.description}>
              <span>Fund Benchmarking (SFB):</span> Innovative Fondsanalyse auf
              höchstem Niveau
            </StyledContent>
            <StyledContent variant="body" color={COLORS.typography.description}>
              Unser Smart Fund Benchmarking (SFB) revolutioniert die Art und
              Weise, wie wir Fonds innerhalb ihrer Peer-Gruppen analysieren und
              vergleichen. Mit diesem innovativen Ansatz übersetzen wir komplexe
              quantitative Daten in klare, verständliche Aussagen für eine
              effektive Fondsanalyse und -selektion
            </StyledContent>
          </div>
          <StyledContent variant="body" color={COLORS.typography.description}>
            <span>Relative Betrachtung und ganzheitliche Bewertung: </span>
            Identifizieren Sie die einzigartigen Merkmale jedes Fonds im
            Vergleich zum Peer-Gruppen-Durchschnitt und profitieren Sie von
            unserer umfassenden Bewertungssystematik. So berücksichtigt das SFB
            neben Rendite- und Risikoaspekten zusätzlich Kriterien wie bspw. den
            Fondsansatz, Track Record oder die Stiltreue.
          </StyledContent>
          <StyledContent variant="body" color={COLORS.typography.description}>
            Unser innovativer Ansatz deckt breites Spektrum an Anlageklassen ab,
            darunter Aktien-, Renten- und Mischfonds. Mit über 6.000
            analysierten Fonds und monatlichen Aktualisierungen bieten wir Ihnen
            stets aktuelle und umfassende Einblicke in die Fondswelt. Entdecken
            Sie mit unserem Smart Fund Benchmarking eine neue Dimension der
            Fondsanalyse.
          </StyledContent>
        </StyledSectionContainer>
        <StyledSectionContainer
          id={esgCredibilityScore.id}
          className={esgCredibilityScore.id}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <StyledHeader variant="h2">ESG Credibility Score</StyledHeader>
          <StyledContent variant="body" color={COLORS.typography.description}>
            FondsConsult bewertet und rankt die Glaubwürdigkeit von über 120
            Fondsgesellschaften in Bezug auf ihre Nachhaltigkeitsbemühungen.
            Grundlage ist ein von FondsConsult entwickelter Ansatz, der die
            Glaubwürdigkeit anhand von Unternehmensangaben und einer intuitiven
            Methodik misst. Dieser ganzheitliche Top-Down-Ansatz geht über
            ESG-Ratings einzelner Fondsbestände hinaus und bietet Investoren
            einen umfassenden Überblick über die Nachhaltigkeits-DNA der
            jeweiligen Fondsgesellschaften.
          </StyledContent>
        </StyledSectionContainer>
        <StyledSectionContainer
          id={privateMarkets.id}
          className={privateMarkets.id}
          display="flex"
          flexDirection="column"
          gap={3}
        >
          <StyledHeader variant="h2">
            Private Markets & Real Estate Fonds
          </StyledHeader>
          <StyledContent variant="body" color={COLORS.typography.description}>
            Bei FondsConsult bieten wir fundierte Analysen und
            Handlungsempfehlungen für Private Market Fonds, sei es im
            ELTIF-Mantel, als offenes Infrastruktur-Sondervermögen oder für
            offene Immobilienfonds. Unser Leistungsspektrum umfasst
            Infrastrukturfonds, Private Equity und Private Debt Fonds.
            Zusätzlich erstellen wir für offene Immobilienfonds quartalsweise
            Risikoeinschätzungen. Unsere proprietäre Bewertungsmethode
            berücksichtigt die Zielgruppenangemessenheit und ermöglicht präzise,
            auf die Bedürfnisse unserer Kunden zugeschnittene Empfehlungen.
            Nutzen Sie unsere Expertise, um Ihre Investitionsstrategien im
            Bereich Private Markets und Real Estate optimal umzusetzen.
          </StyledContent>
        </StyledSectionContainer>
      </Box>
    </StyledContainer>
  );
};
