import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-70px);
  }
`;

type StyledFixedHeaderContainerProps = {
  showFixedHeader: boolean;
};

export const StyledFixedHeaderContainer = styled(
  Box,
)<StyledFixedHeaderContainerProps>`
  position: fixed;
  top: 0;
  z-index: 1;

  background-color: ${COLORS.background.secondary};
  width: 100%;

  animation: ${({ showFixedHeader }) => (showFixedHeader ? fadeIn : fadeOut)}
    0.5s ease-in-out;
`;

export const StyledContainer = styled(Box)`
  background-color: ${COLORS.background.module};
`;

export const StyledInnerContainer = styled(Box)`
  padding: ${SIZES.spacing(3)} ${SIZES.spacing(2.5)} ${SIZES.spacing(12)};

  ${SIZES.media.tablet} {
    padding: ${SIZES.spacing(3)} ${SIZES.spacing(30)} ${SIZES.spacing(12)}
      ${SIZES.spacing(8)};
  }
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 600;
`;

export const StyledContent = styled(Typography)`
  font-size: 16px;
  line-height: 29px;

  & span {
    font-weight: 600;
    color: ${COLORS.typography.main};
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 36px;
  margin-bottom: 16px;
`;

const linkStyles = css`
  color: ${COLORS.typography.body};
  transition-duration: 0.2s;

  &:hover {
    color: ${COLORS.accent.primary};
    text-decoration: none;
  }
`;

export const StyledInnerLink = styled(Link)`
  ${linkStyles}
`;

export const StyledLink = styled.a`
  ${linkStyles}
`;
