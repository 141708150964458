import { Box } from 'components/common/Box';
import styled, { css } from 'styled-components';
import BackgroundImage from 'assets/backgrounds/menu-mobile.jpg';
import {
  StyledInnerContainerProps,
  StyledLinkProps,
} from './MobileNavigationMenu.d';
import { COLORS } from 'theme/colors';
import { Typography } from 'components/common/Typography';
import { HashLink as Link } from 'react-router-hash-link';

export const StyledContainer = styled.div`
  display: block;
  position: relative;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
`;

export const StyledInnerContainer = styled(Box)<StyledInnerContainerProps>`
  position: fixed;
  top: ${({ open }) => (open ? '0' : '-100%')};
  left: 0;
  width: 100%;
  border-radius: 0 0 16px 16px;
  background-color: #333;
  background-image: url(${BackgroundImage});
  background-size: cover;
  transition: top 0.7s ease-in-out;
  z-index: 50;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

export const StyledOverlay = styled.div`
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: transform 1.2s;
`;

export const StyledLogoLink = styled(Link)<StyledLinkProps>`
  ${({ isClickable }) => !isClickable && `pointer-events: none;`}
`;

const linkStyles = css`
  font-family: 'Random Grotesque Standard', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-decoration: none;
  color: ${COLORS.info.background};
`;

export const StyledLink = styled(Link)<StyledLinkProps>`
  ${linkStyles}
  ${({ isClickable }) => !isClickable && `pointer-events: none;`}
`;

export const StyledDot = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${COLORS.background.secondary};
`;

export const MenuSectionName = styled(Typography)`
  opacity: 0.7;
  letter-spacing: 0.1em;
  font-size: 10px;
  line-height: 12px;
  margin-top: 24px;
`;

export const MenuLinkItem = styled.li<StyledLinkProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ isClickable }) => !isClickable && `pointer-events: none;`}

  ${({ isClickable }) =>
    !isClickable &&
    css`
      ${StyledLink} {
        color: ${COLORS.background.secondary};
      }

      svg {
        stroke: ${COLORS.background.secondary};
      }
    `}
`;

export const StyledLinks = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline-start: 0;
  margin: 0;
`;

export const StyledLinksContainer = styled(Box)`
  height: 70vh;
  overflow: auto;
`;
