import { FC, useEffect, useState } from 'react';
import {
  StyledContainer,
  StyledFixedHeaderContainer,
  StyledFormContainer,
  StyledHeader,
  StyledSubHeader,
  StyledTopContainer,
} from './Contacts.styled';
import { Header } from 'components/common/Header';
import { ContactSection } from 'components/common/ContactSection';
import { Footer } from 'components/common/Footer';
import { ContactUsForm } from 'components/Contacts/ContactUsForm';
import { SEO } from 'components/common/SEO';
import { content, description, imageUrl, title } from './config';
import { useWindowSize } from 'hooks/useWindowSize';

export const Contacts: FC = () => {
  const { isMobile } = useWindowSize();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showFixedHeader, setShowFixedHeader] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (isMobile) {
      if (offset < prevScrollPos && offset > 70) {
        setShowFixedHeader(true);
      } else {
        setShowFixedHeader(false);
      }
    }
    setPrevScrollPos(offset);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, prevScrollPos]);

  return (
    <>
      <SEO
        title={title}
        description={description}
        imageUrl={imageUrl}
        content={content}
      />
      <StyledContainer>
        <Header />
      </StyledContainer>
      {showFixedHeader && (
        <StyledFixedHeaderContainer showFixedHeader={showFixedHeader}>
          <Header />
        </StyledFixedHeaderContainer>
      )}
      <StyledContainer>
        <StyledTopContainer
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <StyledHeader variant="h1">Kontakt</StyledHeader>
          <StyledSubHeader variant="body">
            Kontaktieren Sie uns über das untenstehende Formular und wir werden
            uns umgehend bei Ihnen melden
          </StyledSubHeader>
        </StyledTopContainer>
        <StyledFormContainer>
          <ContactUsForm />
        </StyledFormContainer>
      </StyledContainer>
      <ContactSection />
      <Footer />
    </>
  );
};
