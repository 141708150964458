import { navigationItems as methodologyNavItem } from 'components/Methodology/MainSection/config';
import { navigationItems as aboutUsNavItem } from 'views/AboutUs/config';
import { AppPaths } from 'urls/frontend';

const [
  fundSelectionApproach,
  smartFundBenchmarking,
  esgCredibilityScore,
  privateMarkets,
] = methodologyNavItem;

const { services, team, fcic } = aboutUsNavItem;

export const legalInfoLinks = [
  { label: 'Impressum', path: AppPaths.impressum },
  { label: 'Disclaimer', path: AppPaths.disclamer },
  { label: 'Datenschutzerklärung nach DSGVO', path: AppPaths.privacyPolicy },
];

export const navigationItems = [
  {
    categoryName: 'Über uns',
    items: [
      {
        label: 'Dienstleistungen',
        link: `${AppPaths.aboutUs}#${services.id}`,
      },
      {
        label: 'Team',
        link: `${AppPaths.aboutUs}#${team.id}`,
      },
      {
        label: 'FondsConsult Investment Committee (FCIC)',
        link: `${AppPaths.aboutUs}#${fcic.id}`,
      },
      {
        label: 'Kontakt',
        link: AppPaths.contacts,
      },
    ],
  },
  {
    categoryName: 'Methodik',
    items: [
      {
        label: 'FCR Fondsselektion',
        link: `${AppPaths.methodology}#${fundSelectionApproach.id}`,
      },
      {
        label: 'Smart Fund Benchmarking',
        link: `${AppPaths.methodology}#${smartFundBenchmarking.id}`,
      },
      {
        label: 'ESG Credibility Score',
        link: `${AppPaths.methodology}#${esgCredibilityScore.id}`,
      },
      {
        label: 'Private Markets',
        link: `${AppPaths.methodology}#${privateMarkets.id}`,
      },
    ],
  },
];
