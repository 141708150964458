export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
}
