import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';
import { SIZES } from 'theme/sizes';

type StyledItemContainerProps = {
  isLight: boolean;
};

export const StyledContainer = styled(Box)`
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)} 0;

  ${SIZES.media.desktop} {
    padding: ${SIZES.spacing(8.75)} ${SIZES.spacing(8)} 0;
  }
`;

export const StyledTopContainer = styled(Box)`
  flex-direction: column;
  gap: ${SIZES.spacing(5)};
  padding-bottom: ${SIZES.spacing(5)};

  ${SIZES.media.tablet} {
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: ${SIZES.spacing(8.75)};
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 32px;
  line-height: 38px;
  flex: 0 1 70%;

  ${SIZES.media.desktop} {
    font-size: 40px;
  }
`;

export const StyledInnerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${SIZES.spacing(2.5)};

  ${SIZES.media.phone} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 19px;
  }

  ${SIZES.media.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 19px;
  }
`;

export const StyledItemContainer = styled(Box)<StyledItemContainerProps>`
  background-color: ${({ isLight }) =>
    isLight ? COLORS.background.module : COLORS.info.selectedBorder};
  border-radius: 16px;
  padding: ${SIZES.spacing(5)} ${SIZES.spacing(2.5)};

  ${SIZES.media.tablet} {
    padding: ${SIZES.spacing(4)};
  }
`;

export const StyledLabel = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export const StyledItemsList = styled.ul`
  padding-inline-start: 16px;
`;

export const StyledItem = styled.li<StyledItemContainerProps>`
  font-size: 12px;
  line-height: 25px;
  font-family: 'Inter', sans-serif;

  color: ${({ isLight }) =>
    isLight ? COLORS.typography.description : COLORS.typography.neutral};
`;
