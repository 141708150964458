import { FC, useEffect, useRef } from 'react';
import { ExpandedNavMenuProps } from './ExpandedNavMenu.d';
import {
  StyledCategory,
  StyledLink,
  StyledMenu,
  StyledMenuItem,
} from './ExpandedNavMenu.styled';

export const ExpandedNavMenu: FC<ExpandedNavMenuProps> = ({
  variant,
  anchorEl,
  categoryName,
  menuItems,
  open,
  setAnchorEl,
}) => {
  const isPrimaryVariant = variant === 'primary';
  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node)
    ) {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <StyledMenu
      id={`${categoryName}-menu`}
      isLight={isPrimaryVariant}
      anchorEl={anchorEl}
      open={open}
      ref={popperRef}
      disablePortal
      autoFocus={false}
      onMouseLeave={handleClose}
      placement="bottom-start"
    >
      <StyledCategory isLight={isPrimaryVariant}>{categoryName}</StyledCategory>
      {menuItems.map((item) => (
        <StyledMenuItem
          onClick={handleClose}
          isLight={isPrimaryVariant}
          disableRipple
        >
          <StyledLink smooth to={item.link} isLight={isPrimaryVariant}>
            {item.label}
          </StyledLink>
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};
