import styled, { css } from 'styled-components';
import { TypographyProps } from './Typography.d';
import { SIZES } from '../../../theme/sizes';
import { COLORS } from '../../../theme/colors';

const typography: any = css<TypographyProps>`
  color: ${COLORS.typography.main};
  ${({ color }) => color && `color: ${color};`}
  font-family: "Inter", sans-serif;
  font-weight: ${({ weight }) => SIZES.fontWeight[weight || 'regular']};
  margin: 0;
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  transition-duration: 0.2s;
`;

export const Header1 = styled.h1`
  ${typography}
  font-size: 25px;
  font-family: 'Random Grotesque Standard', sans-serif;
  font-weight: 700;
  line-height: 57px;

  ${SIZES.media.phone} {
    font-size: 48px;
  }
`;

export const Header2 = styled.h2`
  ${typography}
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  font-family: 'Random Grotesque Standard', sans-serif;

  ${SIZES.media.phone} {
    font-size: 48px;
    line-height: 58px;
  }
`;

export const Header3 = styled.h3`
  ${typography}
  font-family: "Random Grotesque Standard", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
`;

export const Header4 = styled.h4`
  ${typography}
  font-family: "Random Grotesque Standard", sans-serif;
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
`;

export const Header5 = styled.h5`
  ${typography}
  font-family: "Random Grotesque Standard", sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
`;

export const Header6 = styled.h6`
  ${typography}
  font-family: "Random Grotesque Standard", sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
`;

export const Subtitle = styled.h4`
  ${typography}
  font-size: 16px;
  line-height: 29px;
`;

export const Body = styled.p`
  ${typography}
  font-size: 14px;
  line-height: 23px;

  ${SIZES.media.phone} {
    font-size: 14px;
  }
`;

export const Caption = styled.span`
  ${typography}
  font-size: 14px;
  line-height: 17px;

  @media screen and (max-width: ${SIZES.deviceSize.tablets}px) {
    font-size: 12px;
    line-height: 30px;
  }
`;

export const Overline = styled.span`
  ${typography}
  font-family: "Random Grotesque Standard", sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

export const Link = styled.span`
  ${typography}
  border-bottom: 1px solid ${COLORS.accent.primary};
  color: ${COLORS.accent.primary};
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: ${SIZES.fontWeight.bold};
  line-height: 24px;
`;
