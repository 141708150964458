import styled from 'styled-components';
import { Box } from 'components/common/Box';
import BackgroundImage from 'assets/backgrounds/main-section.jpg';
import MobileBackgroundImage from 'assets/backgrounds/main-section-mobile.jpg';
import { Typography } from '../../common/Typography';
import { COLORS } from '../../../theme/colors';
import { SIZES } from 'theme/sizes';

export const StyledContainer = styled(Box)`
  width: 100%;
  height: 100vh;
  background-image: url(${MobileBackgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-size: cover;

  ${SIZES.media.tablet} {
    background-image: url(${BackgroundImage});
    background-size: cover;
  }
`;

export const StyledInnerContainer = styled(Box)`
  min-height: calc(100vh - 58.4px);

  ${SIZES.media.tablet} {
    display: grid;
    align-items: end;
    grid-template-columns: minmax(330px, 1fr) minmax(310px, 1fr);

    min-height: calc(100vh - 58.4px);
  }

  ${SIZES.media.desktop} {
    grid-template-columns: minmax(730px, 1fr) minmax(710px, 1fr);
  }
`;

export const StyledTopContainer = styled(Box)`
  padding-left: ${SIZES.spacing(2.5)};
  padding-right: ${SIZES.spacing(2.5)};
  height: 90vh;

  ${SIZES.media.tablet} {
    padding-left: ${SIZES.spacing(8)};
    height: 100%;
  }
`;

export const StyledTopInnerContainer = styled(Box)`
  padding-top: calc(calc(-420px + 100vh - 50%));

  ${SIZES.media.tablet} {
    padding-top: 4vw;
  }

  ${SIZES.media.desktop} {
    padding-top: calc(calc(-300px + 100vh - 50%));
  }
`;

export const StyledHeader = styled(Typography)`
  font-size: 52px;
  line-height: 57px;
  font-weight: 700;
  color: ${COLORS.background.secondary};

  ${SIZES.media.phone} {
    font-size: 65px;
    line-height: 69px;
  }

  ${SIZES.media.tablet} {
    font-size: 90px;
    line-height: 99px;
  }
`;

export const StyledSubHeader = styled(Typography)`
  font-size: 16px;
  line-height: 23px;
  color: ${COLORS.background.secondary};

  margin-top: 24px;
  width: 80%;

  ${SIZES.media.tablet} {
    font-size: 24px;
    line-height: 35px;
  }
`;

export const StyledButtonContainer = styled(Box)`
  padding-top: ${SIZES.spacing(5)};

  ${SIZES.media.tablet} {
    padding-top: ${SIZES.spacing(6)};
  }
`;

export const StyledTagsContainer = styled(Box)`
  padding-top: ${SIZES.spacing(2)};
  padding-bottom: ${SIZES.spacing(2)};
  ${SIZES.media.tablet} {
    padding-top: ${SIZES.spacing(3)};
    padding-bottom: ${SIZES.spacing(4.1)};
  }
`;
